import request from '@/utils/request'

// 收藏/取消收藏
export function userInfo(data) {
  return request({
    url: 'user/userInfo',
    method: 'post',
    data
  })
}

export function tokenLogin(data) {
  return request({
    url: 'token/login',
    method: 'post',
    data
  })
}