<template>
  <div class="my-container">
    <!-- 个人详情 -->
    <div class="head">
      <div class="left">
        <van-image round :src="userinfo.headimgurl" fit="cover">
          <template v-slot:error></template>
        </van-image>
      </div>
      <div class="right">
        <div class="name">{{ userinfo.nickname }}</div>
        <!-- 已登陆 -->
        <div class="info">
          <div class="auth">{{ author ? "已认证" : "未认证" }}</div>
          <!-- <div class="num">
            我的次数:<span class="bd">{{ userinfo.viewnum }}</span
            >次
          </div> -->
        </div>
      </div>
    </div>
    <!-- 每日任务 -->
    <div class="task">
      <!-- <div class="task-item" @click="$router.push('/task')">
        <img src="../../assets/images/me_icon_the daily task@3x.png" />
        <div class="name">每日任务</div>
      </div> -->
      <div class="task-item" @click="$router.push('/attention')">
        <img src="../../assets/images/me_icon_drug@3x.png" />
        <div class="name">关注药品</div>
      </div>
      <div class="task-item" @click="$router.push('/record')">
        <img src="../../assets/images/me_icon_record@3x.png" />
        <div class="name">浏览记录</div>
      </div>
    </div>
    <!-- 分享 -->
    <div class="link">
      <van-cell
        @click="shareShow = true"
        :center="true"
        title="分享给好友"
        is-link
      />
      <van-cell
        @click="showPopover = true"
        :center="true"
        title="关于我们"
        is-link
      />
    </div>
    <!-- 退出登录 -->
    <!-- <div class="logout" @click="logout">退出登录</div> -->
    <!-- 分享好友 -->
    <van-overlay :show="shareShow" @click="shareShow = false">
      <img class="share" src="../../assets/images/icon_guide@3x.png" alt="" />
    </van-overlay>
    <!-- 关于我们模块 -->
    <van-action-sheet v-model="showPopover" title="关于我们">
      <div class="content">
        <div class="descrie">
          <p>「药先生」是书秘教育出品的药学在线教育平台，为你提供以下服务：</p>
          <p>①临床药学/医院药学用药查询，贴合临床应用、更为详细的药品信息</p>
          <p>②在线学习视频课程与执业、职称考试内容</p>
          <p>③专业出品的数万道药学复习题库</p>
          <p>④临床药学/医院药学新鲜资讯与院内用药总结</p>
        </div>
        <div class="ercode">
          <img src="../../assets/images/two-dimensional code@3x.png" alt="" />
        </div>
        <div class="info">搜索“药先生”或扫描二维码关注公众号</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { userInfo } from '@/api/user'
export default {
  data() {
    return {
      shareShow: false, // 分享好友
      showPopover: false, // 关于我们
      userinfo: {},
      author: false
    }
  },
  methods: {
    async getUserInfo() {
      try {
        const { data } = await userInfo({ id: 1 })
        this.userinfo = data
        localStorage.setItem('userInfo', JSON.stringify(data))
        this.author = true
      } catch (error) {
        this.author = false
      }
    },
    logout () {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      var path = this.$route.fullPath;
      var redirect_uri = encodeURIComponent('http://yxs.huapeiwang.cn/login');
      var url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx08fbe99d74609235&redirect_uri=' +
        redirect_uri + '&response_type=code&scope=snsapi_userinfo&state=' + path + '#wechat_redirect';
      window.location.href = url;
    } else {
      this.getUserInfo()
    }
  }

}
</script>

<style lang="less" scoped>
.my-container {
  padding: 0 21px;
  background-color: #f9faf9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  .head {
    display: flex;
    margin-top: 16px;
    margin-bottom: 23px;
    .left {
      width: 68px;
      height: 68px;
      margin-right: 17px;
      /deep/ .van-image {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      padding-top: 8px;
      .name {
        font-size: 18px;
        color: #000;
      }
      .info {
        display: flex;
        margin-top: 7px;
        .auth {
          width: 58px;
          height: 21px;
          margin-right: 14px;
          background: #42300f;
          border-radius: 11px;
          border: 1px solid #e8c695;

          color: #e2bd88;
          font-size: 10px;
          text-align: center;
          line-height: 21px;
        }
        .num {
          padding: 0 11px;
          font-size: 12px;
          color: #000000;
          height: 21px;
          border: 1px solid #eeeeee;
          text-align: center;
          line-height: 21px;
          border-radius: 11px;
          .bd {
            color: red;
            margin-left: 4px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .task {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 88px;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(234, 234, 234, 0.5);
    border-radius: 8px;
    .task-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
      }
      .name {
        font-size: 12px;
        color: #3d3d3d;
      }
    }
  }
  .link {
    overflow: hidden;
    width: 333px;
    box-shadow: 0px 0px 8px 0px rgba(234, 234, 234, 0.5);
    border-radius: 8px;
    margin-top: 14px;
    .vant-icon {
      width: 18px;
      height: 18px;
    }
    /deep/ .van-cell {
      position: relative;
      padding-left: 35px;
      height: 48px;
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        width: 18px;
        height: 18px;
      }

      &:nth-child(1) {
        &::before {
          background: url("../../assets/images/me_icon_share@3x.png");
          background-size: 18px 18px;
        }
      }
      &:nth-child(2) {
        &::before {
          background: url("../../assets/images/me_icon_about@3x.png");
          background-size: 18px 18px;
        }
      }
    }
  }
  /deep/ .van-button--default {
    width: 100%;
    height: 44px;
    box-shadow: 0px 0px 8px 0px rgba(234, 234, 234, 0.5);
    border-radius: 8px;
    border: none;
    color: #ec0909;
  }
  .share {
    width: 232px;
    height: 234px;
    position: absolute;
    top: 5px;
    right: 17px;
  }
  .content {
    padding: 60px 22px 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .descrie {
      p {
        font-size: 16px;
        color: #333;
      }
    }
    .ercode {
      width: 119px;
      height: 119px;
      margin-top: 65px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      margin-top: 27px;
      font-size: 14px;
      color: #666;
    }
  }
  .logout {
    width: 100%;
    margin-top: 20px;
    background-color: #2364e8;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
}
</style>